(function () {
    "use strict";
    angular.module('aerosApp')
        .factory('UnitsMeasurementService', UnitsMeasurementService);

    UnitsMeasurementService.$inject = [];

    function UnitsMeasurementService() {

        var consts = {
            METER: {
                FULL: 'Meters',
                SHORT: 'm'
            },
            FEET: {
                FULL: 'Feet',
                SHORT: 'ft'
            },
            MILE: {
                FULL: 'Miles',
                SHORT: 'mi'
            },
            KILOMETER: {
                FULL: 'Kilometers',
                SHORT: 'km'
            },
            KILOFEET: {
                FULL: 'Kilofeet',
                SHORT: 'kft'
            }
        };

        var service = {
            metersToFeet: metersToFeet,
            metersToMiles: metersToMiles,
            metersToKilofeet: metersToKilofeet,
            metersToKilometers: metersToKilometers,
            metersTo: metersTo,
            toMeters: toMeters,
            metersToPrecise: metersToPrecise,
            feetToMeters: feetToMeters,
            milesToMeters: milesToMeters,

            dBKmTodBKft: dBKmTodBKft,
            dBKftToDBKm: dBKftToDBKm,
            dBKmTodBMi: dBKmTodBMi,
            dBMiTodBKm: dBMiTodBKm,


            getUnit: getUnit,
            getConverters: getConverters
        };

        angular.extend(service, consts);

        // The non-precise methods round to three decimal places.
        // They does it by multiplying by 1000 within the round method(the multiplication is built into the divisor)
        // and then dividing by 1000.

        function metersToFeet(meter) {
            return Math.round(meter * 3280.839895) / 1000;
        }

        function metersToFeetPrecise(meter) {
            return meter * 3.280839895;
        }

        function metersToKilofeet(meter) {
            return Math.round(meter * 3.280839895) / 1000;
        }

        function metersToKilofeetPrecise(meter) {
            return meter * .003280839895;
        }

        function metersToMiles(meter) {
            return Math.round(0.6213711922 * meter) / 1000;
        }

        function metersToMilesPrecise(meter) {
            return 0.0006213711922 * meter;
        }

        function metersToKilometers(meter) {
            return Math.round(meter) / 1000;
        }

        function metersToKilometersPrecise(meter) {
            return meter / 1000;
        }

        function dBKmTodBKftPrecise(dbKm) {
            return dbKm * metersToFeetPrecise(1);
        }

        function dBKmTodBKft(dbKm) {
            return Math.round(dBKmTodBKftPrecise(dbKm) * 1000) / 1000;
        }

        function dBKftToDBKmPrecise(dBKft) {
            return dBKft * metersToFeetPrecise(1);
        }

        function dBKftToDBKm(dBKft) {
            return Math.round(dBKftToDBKmPrecise(dBKft) * 1000) / 1000;
        }

        function dBKmTodBMiPrecise(dBKm) {
            return dBKm / metersToMilesPrecise(1000);
        }

        function dBKmTodBMi(dBKm) {
            return Math.round(dBKmTodBMiPrecise(dBKm) * 1000) / 1000;
        }

        function dBMiTodBKmPrecise(dBMi) {
            return dBMi * metersToMilesPrecise(1000);
        }

        function dBMiTodBKm(dBMi) {
            return Math.round(dBMiTodBKmPrecise(dBMi) * 1000) / 1000;
        }

        function metersTo(unit, meter) {
            switch (unit) {
                case consts.FEET.FULL:
                    return metersToFeet(meter);
                case consts.MILE.FULL:
                    return metersToMiles(meter);
                case consts.KILOFEET.FULL:
                    return metersToKilofeet(meter);
                case consts.KILOMETER.FULL:
                    return metersToKilometers(meter);
                default:
                    return meter;
            }
        }

        function toMeters(unit, value) {
            switch (unit) {
                case consts.FEET.FULL:
                    return feetToMeters(value);
                case consts.MILE.FULL:
                    return milesToMeters(value);
                case consts.KILOFEET.FULL:
                    return kiloFeetToMeters(value);
                case consts.KILOMETER.FULL:
                    return kilometersToMeters(value);
                default:
                    return value;
            }
        }

        function metersToPrecise(unit, meter) {
            switch (unit) {
                case consts.FEET.FULL:
                    return metersToFeetPrecise(meter);
                case consts.MILE.FULL:
                    return metersToMilesPrecise(meter);
                case consts.KILOFEET.FULL:
                    return metersToKilofeetPrecise(meter);
                case consts.KILOMETER.FULL:
                    return metersToKilometersPrecise(meter);
                default:
                    return meter;
            }
        }

        function feetToMeters(feet) {
            return Math.round(feet / .003280839895) / 1000;
        }

        function kiloFeetToMeters(kfeet) {
            return Math.round(kfeet / .000003280839895) / 1000;
        }

        function milesToMeters(miles) {
            return Math.round(miles / 0.0000006213711922) / 1000;
        }

        function kilometersToMeters(km) {
            return Math.round(km * 1000000) / 1000;
        }

        function getUnit(unit) {
            return consts[Object.keys(consts).filter(
                function (item) {
                    return unit === consts[item].FULL;
                }
            )];
        }

        function getConverters(unitsCode) {
            var units = unitsCode.split('|'),
                converters = {
                    baseUnitConverter: undefined,
                    showUnitConverter: undefined
                };

            switch (units[0]) {
                case 'm':
                    switch (units[1]) {
                        case 'ft':
                            return {
                                baseUnitConverter: service.feetToMeters,
                                showUnitConverter: service.metersToFeet
                            };
                        case 'mi':
                            return {
                                baseUnitConverter: service.milesToMeters,
                                showUnitConverter: service.metersToMiles
                            };
                    }
                    break;
                case 'dB/km':
                    switch (units[1]) {
                        case 'dB/kft':
                            return {
                                baseUnitConverter: service.dBKftToDBKm,
                                showUnitConverter: service.dBKmTodBKft
                            };
                        case 'dB/mi':
                            return {
                                baseUnitConverter: service.dBMiTodBKm,
                                showUnitConverter: service.dBKmTodBMi
                            };
                    }
                    break;
            }

            return converters;
        }

        return service;
    }

})();
